import { Component, OnInit } from '@angular/core';
import { Menu } from 'src/app/core/models/interfaces/menu';
import { Helper } from 'src/app/core/utils';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/global/user.service';
import { AuthService } from 'src/app/core/services/global/auth.service';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss']
})
export class PrivateComponent implements OnInit {

  menu: Menu[] = [];
  constructor(public router: Router, public userService: UserService, public authService: AuthService) { }

  ngOnInit(): void {
    Helper.fetchDataJson((menu: Menu[]) => {
      this.menu = menu as Menu[];
    }, '/assets/data/menu.json');
  }

}
