import { Router } from '@angular/router';
import { AppRoutes } from '../models/enums';

export abstract class Redirection {
  static route: Router;

  /**
   * REDIRECTION TO SIGN IN
   */
  static signIn(): void {
    Redirection.route.navigateByUrl(AppRoutes.SIGNIN);
  }

  /**
   * REDIRECTION TO FORGOTPASSWORD
   */
  static forgotPassword(): void {
    Redirection.route.navigateByUrl(AppRoutes.FORGOTPASSWORD);
  }

  /**
   * REDIRECTION TO ACTUALITY FORM
   */
  static actualityForm(): void {
    Redirection.route.navigateByUrl(AppRoutes.ACTUALITYFORM);
  }

  /**
   * REDIRECTION TO ACTUALITY LISTING
   */
  static actualityListing(): void {
    Redirection.route.navigateByUrl(AppRoutes.ACTUALITYLISTING);
  }

  /**
   * REDIRECTION TO analytics
   */
  static analytics(): void {
    Redirection.route.navigateByUrl(AppRoutes.ANALYTICSMODULE);
  }

  /**
   * REDIRECTION TO PROFILE CONTENT FORM
   */
  static profileContentForm(): void {
    Redirection.route.navigateByUrl(AppRoutes.PROFILECONTENTFORM);
  }

  /**
   * REDIRECTION TO PROFILE CONTENT LISTING
   */
  static profileContentListing(): void {
    Redirection.route.navigateByUrl(AppRoutes.PROFILECONTENTLISTING);
  }

  /**
   * REDIRECTION TO BANNER FORM
   */
  static bannerForm(): void {
    Redirection.route.navigateByUrl(AppRoutes.BANNERFORM);
  }

  /**
   * REDIRECTION TO BANNER LISTING
   */
  static bannerListing(): void {
    Redirection.route.navigateByUrl(AppRoutes.BANNERLISTING);
  }

  /**
   * REDIRECTION TO ROLE LISTING
   */
  static roleListing(): void {
    Redirection.route.navigateByUrl(AppRoutes.ROLELISTING);
  }

  /**
   * REDIRECTION TO USER LISTING
   */
  static userListing(): void {
    Redirection.route.navigateByUrl(AppRoutes.USERLISTING);
  }
  /**
   * REDIRECTION TO univerisity LISTING
   */
  static universityLinsting(): void {
    Redirection.route.navigateByUrl(AppRoutes.UNIVERSITYLISTING);
  }
  /**
   * REDIRECTION TO edit university
   */
  static editUniversity(id): void {
    Redirection.route.navigate([AppRoutes.UNIVERSITYFORM + '/' + id]);
  }

  /**
   * REDIRECTION TO  university cbhe contact
   */
  static universityCbhe(): void {
    Redirection.route.navigateByUrl(AppRoutes.UNIVERSITYCBHECONTACTS);
  }

  /**
   * REDIRECTION TO  university icm contact
   */
  static universityIcm(): void {
    Redirection.route.navigateByUrl(AppRoutes.UNIVERSITYICMCONTACTS);
  }

  /**
   * REDIRECTION TO  university emjmd contact
   */
  static universityEmjmd(): void {
    Redirection.route.navigateByUrl(AppRoutes.UNIVERSITYEMJMDCONTACTS);
  }
  /**
   * REDIRECTION TO  university emjmd contact
   */
  static universityJeanMonnet(): void {
    Redirection.route.navigateByUrl(AppRoutes.UNIVERSITYJEANMONNETCONTACTS);
  }
  /**
   * REDIRECTION TO  university establishments contact
   */
  static universityEstablishments(): void {
    Redirection.route.navigateByUrl(AppRoutes.UNIVERSITYESTABLISHMENTSCONTACTS);
  }
  /**
   * REDIRECTION TO  university trianning offers contact
   */
  static universityTrainingOffers(): void {
    Redirection.route.navigateByUrl(AppRoutes.UNIVERSITYTRAININGOFFERSCONTACTS);
  }
  /**
   * REDIRECTION TO  university lessons contact
   */
  static universityLessons(): void {
    Redirection.route.navigateByUrl(AppRoutes.UNIVERSITYLESSONSCONTACTS);
  }
  /**
   * REDIRECTION TO  university international realtions contact
   */
  static universityInternationalRealaions(): void {
    Redirection.route.navigateByUrl(
      AppRoutes.UNIVERSITYINTERNATIONALRELATIONSCONTACTS
    );
  }
  /**
   * REDIRECTION TO  cbhe lsiting
   */
  static cbhe(): void {
    Redirection.route.navigateByUrl(AppRoutes.CBHELISTING);
  }
  /**
   * REDIRECTION TO  emjmd lsiting
   */
  static emjmd(): void {
    Redirection.route.navigateByUrl(AppRoutes.EMJMDLISTING);
  }
  /**
   * REDIRECTION TO  jeanmoeet  lsiting
   */
  static jeanMonnet(): void {
    Redirection.route.navigateByUrl(AppRoutes.JEANMONNETLISTING);
  }

  /**
   * REDIRECTION TO  icm  lsiting
   */
  static icmListing(): void {
    Redirection.route.navigateByUrl(AppRoutes.ICMLISTING);
  }
  /**
   * REDIRECTION TO  icm approved  lsiting
   */
  static icmApprovedListing(): void {
    Redirection.route.navigateByUrl(AppRoutes.ICMAPPROUVEDLISTING);
  }
  /**
   * REDIRECTION TO  icm tools  lsiting
   */
  static icmToolsListing(): void {
    Redirection.route.navigateByUrl(AppRoutes.ICMTOOLSLISTING);
  }
  /**
   * REDIRECTION TO  icm projects  lsiting
   */
  static icmProjectsListing(): void {
    Redirection.route.navigateByUrl(AppRoutes.PROJECTSLISTING);
  }
  /**
   * REDIRECTION TO  icm projects  lsiting
   */
  static mobilitiesListing(): void {
    Redirection.route.navigateByUrl(AppRoutes.MOBILITYSLISTING);
  }
  /**
   * REDIRECTION TO edit icm toools project
   */
  static editIcm(id): void {
    Redirection.route.navigate([AppRoutes.ICMFORM + '/' + id]);
  }
  /**
   * REDIRECTION TO edit mobilities project
   */
  static editMobility(id): void {
    Redirection.route.navigate([AppRoutes.MOBILITYSFORM + '/' + id]);
  }
  /**
   * REDIRECTION TO edit icm aaproved toools project
   */
  static editIcmApproved(id): void {
    Redirection.route.navigate([AppRoutes.ICMAPPROUVEDFORM + '/' + id]);
  }

  /**
   * REDIRECTION TO edit icm aaproved toools project
   */
  static resources(): void {
    Redirection.route.navigateByUrl(AppRoutes.RESOURCESMODULE);
  }
  /**
   * REDIRECTION TO resources files
   */
  static resourcesFiles(): void {
    Redirection.route.navigateByUrl(AppRoutes.REOURCESFILESLISTING);
  }

  // dynamic part

  /**
   * REDIRECTION TO externalPagesListing
   */
  static externalPagesListing(): void {
    Redirection.route.navigateByUrl(AppRoutes.EXTERNALPAGESLISTING);
  }

  /**
   * REDIRECTION TO pagesListing
   */
  static pagesListing(): void {
    Redirection.route.navigateByUrl(AppRoutes.PAGESLISTING);
  }
  /**
   * REDIRECTION TO publications
   */
  static publications(): void {
    Redirection.route.navigateByUrl(AppRoutes.PUBLICATIONSLISTING);
  }
  /**
   * REDIRECTION TO publicationsFiles
   */
  static publicationsFiles(): void {
    Redirection.route.navigateByUrl(AppRoutes.PUBLICATIONSFILESLISTING);
  }

  /**
   * REDIRECTION TO standard activities listing
   */
  static standardActivitiesListing(): void {
    Redirection.route.navigateByUrl(AppRoutes.STANDARDACTIVITIESLISTING);
  }
  /**
   * REDIRECTION TO reformActivitiesListing
   */
  static reformActivitiesListing(): void {
    Redirection.route.navigateByUrl(AppRoutes.REFORMPROJECTSACTIVITIESLISTING);
  }
  /**
   * REDIRECTION TO projectsActivitiesListing
   */
  static projectsActivitiesListing(): void {
    Redirection.route.navigateByUrl(AppRoutes.PROJECTSACTIVITIESLISTING);
  }
  /**
   * REDIRECTION TO referencesListing
   */
  static referencesListing(): void {
    Redirection.route.navigateByUrl(AppRoutes.REFERENCESLISTING);
  }
  /**
   * REDIRECTION TO documentsLisitng
   */
  static documentsLisitng(): void {
    Redirection.route.navigateByUrl(AppRoutes.DOCUMENTSLISTING);
  }

  /**
   * REDIRECTION TO concernedPersonsDetails
   */
  static concernedPersonsDetails(id?): void {
    Redirection.route.navigate([AppRoutes.CONCERNEDPERSONS + '/' + id]);
  }

  /**
   * REDIRECTION TO erasmusIcmsDetails
   */
  static erasmusIcmsDetails(id?): void {
    Redirection.route.navigate([AppRoutes.ERASMUSICMDETAILS + '/' + id]);
  }
}
