import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { tap, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Response } from '../../models/interfaces';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmationDialogComponent } from 'src/app/shared/components';

@Injectable({
  providedIn: 'root',
})
export class CrudService {
  private rows = new BehaviorSubject<any>([]);
  rows$ = this.rows.asObservable();
  private count = new BehaviorSubject<any>([]);
  count$ = this.count.asObservable();
  private loader = new BehaviorSubject<boolean>(false);
  loader$ = this.loader.asObservable();

  constructor(private apiService: ApiService, private dialog: MatDialog) {}

  /**
   * CREATE
   * @param model
   * @param objetToCreate
   */
  create<T>(model: T | any, objetToCreate: T | any): Observable<T | T[]> {
    this.loader.next(true);
    return this.apiService.post(model.tableName, objetToCreate).pipe(
      tap((value) => {
        this.apiService.snackBarService.success('successfully_created');
      }),
      finalize(() => {
        this.loader.next(false);
      })
    );
  }

  /**
   * READ
   * @param model
   * @param params
   */
  read<T>(model: T | any, params?: HttpParams): Observable<Response | T[]> {
    this.loader.next(true);
    this.setRows();
    return this.apiService.get(model.tableName, params).pipe(
      tap((response: Response) => {
        this.setRows(response.data);
        this.setCount(response.count);
      }),
      finalize(() => {
        this.loader.next(false);
      })
    );
  }

  /**
   * READ ONE
   * @param model
   * @param id
   */
  readOne<T>(model: T | any, id: string): Observable<T> {
    this.loader.next(true);
    return this.apiService.get(model.tableName + '/' + id).pipe(
      tap((response) => {
        return response;
      }),
      finalize(() => {
        this.loader.next(false);
      })
    );
  }

  /**
   * UPDATE
   * @param model
   * @param objectToUpdate
   */
  update<T>(model: T | any, objectToUpdate: T | any): Observable<T | T[]> {
    this.loader.next(true);
    return this.apiService
      .put(model.tableName + '/' + objectToUpdate.id, objectToUpdate.values)
      .pipe(
        tap((response) => {
          this.apiService.snackBarService.success('successfully_updated');
        }),
        finalize(() => {
          this.loader.next(false);
        })
      );
  }

  /**
   * DELETE
   * @param id
   */
  delete<T>(model: T | any, id: string): Observable<T | T[]> {
    this.loader.next(true);
    return this.apiService.delete(`${model.tableName}/${id}`).pipe(
      tap((response) => {
        const rows = this.rows.value;
        const index = rows.findIndex((val) => val.id === id);
        if (index !== -1) {
          rows.splice(index, 1);
          this.setRows([...rows]);
        }
        this.apiService.snackBarService.success('successfully_deleted');
      }),
      finalize(() => {
        this.loader.next(false);
      })
    );
  }

  /**
   * SET ROWS
   * @param rows
   */
  setRows(rows = []) {
    this.rows.next(rows);
  }

  /**
   * SET count
   * @param rows
   */
  setCount(count = 100) {
    this.count.next(count);
  }

  deleteConfirmed(cb) {
    console.log('clicked');
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      console.log(result);
      if (result) {
        cb;
      } else {
        return false;
      }
    });
  }
}
