import { IcmTools } from '../interfaces';

export class IcmToolsProject {
  private _id: number;
  private _university_id: string;
  private _institution_id: string;
  private _country: string;
  private _city: string;
  private _projetYear: number;
  private _projetCode: string;
  static tableName = 'icm-tools-project';

  constructor(icmToolsProject?: any) {
    this.id = icmToolsProject.id;
    this.university_id = icmToolsProject.university_id;
    this.institution_id = icmToolsProject.institution_id;
    this.country = icmToolsProject.country;
    this.city = icmToolsProject.city;
    this.projetYear = icmToolsProject.projetYear;
    this.projetCode = icmToolsProject.projetCode;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get university_id(): string {
    return this._university_id;
  }

  public set university_id(university_id: string) {
    this._university_id = university_id;
  }

  public get institution_id(): string {
    return this._institution_id;
  }

  public set institution_id(institution_id: string) {
    this._institution_id = institution_id;
  }

  public get country(): string {
    return this._country;
  }

  public set country(country: string) {
    this._country = country;
  }

  public get city(): string {
    return this._city;
  }

  public set city(city: string) {
    this._city = city;
  }

  public get projetYear(): number {
    return this._projetYear;
  }

  public set projetYear(projetYear: number) {
    this._projetYear = projetYear;
  }

  public get projetCode(): string {
    return this._projetCode;
  }

  public set projetCode(projetCode: string) {
    this._projetCode = projetCode;
  }

  /**
   * get value
   */
  public get values(): object {
    let formData = new FormData();
    let object = {};
    const properties = [
      'id',
      'university_id',
      'institution_id',
      'country',
      'city',
      'projetYear',
      'projetCode',
    ];
    properties.forEach((item) => {
      if (this[item]) {
        formData.append(item, this[item]);
        object[item] = this[item];
      }
    });
    return object;
    // return formData;
  }
}
