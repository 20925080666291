export class User {
  protected _id: string;
  protected _firstName: string;
  protected _lastName: string;
  protected _email: string;
  protected _role_id: any;
  protected _university_id: any;
  static tableName = 'users';

  constructor(user?) {
    this.id = user.id;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.email = user.email;
    this.role_id = user.role_id;
    this.university_id = user.university_id;
  }

  public get id(): string {
    return this._id;
  }

  public set id(id: string) {
    this._id = id;
  }

  public get firstName(): string {
    return this._firstName;
  }

  public set firstName(firstName: string) {
    this._firstName = firstName;
  }

  public get lastName(): string {
    return this._lastName;
  }

  public set lastName(lastName: string) {
    this._lastName = lastName;
  }

  public get email(): string {
    return this._email;
  }

  public set email(email: string) {
    this._email = email;
  }

  public get role_id(): any {
    return this._role_id;
  }

  public set role_id(role_id: any) {
    this._role_id = role_id;
  }
  public get university_id(): any {
    return this._university_id;
  }

  public set university_id(university_id: any) {
    this._university_id = university_id;
  }

  /**
   * get value
   */
  public get values(): object {
    let formData = new FormData();
    let object = {};
    const properties = [
      'id',
      'firstName',
      'lastName',
      'email',
      'role_id',
      'university_id',
    ];
    properties.forEach((item) => {
      if (this[item]) {
        formData.append(item, this[item]);
        object[item] = this[item];
      }
    });
    return object;
    // return formData;
  }
}
