import { projectType } from '../enums';
import { Partner } from '../interfaces';

export class Project {
  private _id: number;
  private _name: string;
  private _activity: string;
  private _description: string;
  private _startDate: Date;
  private _endDate: Date;
  private _amount: number;
  private _results: string;
  private _contact: string;
  private _projectWebSite: string;
  private _webSite: string;
  private _partner: string;

  constructor(project?: any) {
    this.id = project.id;
    this.name = project.name;
    this.activity = project.activity;
    this.description = project.description;
    this.startDate = project.startDate;
    this.endDate = project.endDate;
    this.amount = project.amount;
    this.results = project.results;
    this.contact = project.contact;
    this.projectWebSite = project.projectWebSite;
    this.partner = project.partner;
    this.webSite = project.webSite;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get activity(): string {
    return this._activity;
  }

  public set activity(activity: string) {
    this._activity = activity;
  }

  public get description(): string {
    return this._description;
  }

  public set description(description: string) {
    this._description = description;
  }

  public get startDate(): Date {
    return this._startDate;
  }

  public set startDate(startDate: Date) {
    this._startDate = startDate;
  }

  public get endDate(): Date {
    return this._endDate;
  }

  public set endDate(endDate: Date) {
    this._endDate = endDate;
  }

  public get amount(): number {
    return this._amount;
  }

  public set amount(amount: number) {
    this._amount = amount;
  }

  public get results(): string {
    return this._results;
  }

  public set results(results: string) {
    this._results = results;
  }

  public get contact(): string {
    return this._contact;
  }

  public set contact(contact: string) {
    this._contact = contact;
  }

  public get projectWebSite(): string {
    return this._projectWebSite;
  }

  public set projectWebSite(projectWebSite: string) {
    this._projectWebSite = projectWebSite;
  }

  public get webSite(): string {
    return this._webSite;
  }

  public set webSite(webSite: string) {
    this._webSite = webSite;
  }

  public get partner(): string {
    return this._partner;
  }

  public set partner(partner: string) {
    this._partner = partner;
  }
  /**
   * get value
   */
  public get values(): object {
    let formData = new FormData();
    let object = {};
    const properties = [
      'id',
      'name',
      'activity',
      'description',
      'startDate',
      'endDate',
      'amount',
      'results',
      'contact',
      'projectWebSite',
      'partner',
      'webSite',
    ];
    properties.forEach((item) => {
      if (this[item]) {
        formData.append(item, this[item]);
        object[item] = this[item];
      }
    });
    return object;
    // return formData;
  }
}
