import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  private durationInSeconds = 3;

  constructor(private snackBarService: MatSnackBar, private trans: TranslationService) { }

  /**
   * success message
   * @param message
   */
  success(message: string) {
    this.snackBarService.open(this.trans.translate(message), '', {
      duration: this.durationInSeconds * 1000
    });
  }

  /**
   * error message
   * @param message
   */
  error(message: string) {
    this.snackBarService.open(this.trans.translate(message), '', {
      duration: this.durationInSeconds * 1000,
      panelClass: 'error'
    });
  }

}
