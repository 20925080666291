import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/components';

@Injectable({
  providedIn: 'root',
})
export class CofirmationDialogService {
  constructor(private dialog: MatDialog) {}

  openDialog() {
    return this.dialog
      .open(ConfirmationDialogComponent, {
        width: '250px',
        data: {},
      })
      .afterClosed();
  }
}
