<div class="page-content">
  <mat-toolbar>
    <mat-toolbar-row>
      <button mat-button class="menu-btn" (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <span class="logo">Erasmus plus</span>
      <span class="spacer"></span>
      <div class="user-infos">
        <button mat-button [matMenuTriggerFor]="matMenu">
          {{ userService.user.firstName }}
          {{ userService.user.lastName }}
        </button>
        <mat-menu #matMenu="matMenu" class="actions">
          <button mat-menu-item (click)="authService.logout()">
            {{ "auth.buttons.logout" | translate }}
          </button>
        </mat-menu>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-drawer-container class="container" autosize>
    <mat-drawer #drawer mode="Over" class="sidenav">
      <mat-selection-list [multiple]="false" class="menu">
        <a
          [routerLink]="[item.path]"
          *ngFor="let item of menu"
          (click)="drawer.close()"
        >
          <mat-list-option [selected]="router.isActive(item.path, false)">
            <mat-icon>{{ item.icon }}</mat-icon>
            <span>{{ item.label | translate }}</span>
          </mat-list-option>
        </a>
      </mat-selection-list>
    </mat-drawer>
    <div class="sidenav-content">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-container>
</div>
