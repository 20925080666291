import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationComponent, PrivateComponent } from './layouts';
import { AppRoutes } from './core/models/enums';
import { AuthorizedGuard, LoggedInGuard } from './core/services/guards';
import { ImportComponent } from './shared/components/import/import.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: AppRoutes.SIGNIN,
  },
  {
    path: AppRoutes.AUTHMODULE,
    component: AuthenticationComponent,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/authentication/authentication.module').then(
            (m) => m.AuthenticationModule
          ),
      },
    ],
  },
  {
    path: '',
    component: PrivateComponent,
    canActivate: [AuthorizedGuard],
    children: [
      {
        path: AppRoutes.ANALYTICSMODULE,
        loadChildren: () =>
          import('./pages/analytic/analytic.module').then(
            (m) => m.AnalyticModule
          ),
      },
      {
        path: AppRoutes.CONTENTMODULE,
        loadChildren: () =>
          import('./pages/content/content.module').then((m) => m.ContentModule),
      },

      {
        path: AppRoutes.ACCOUNTMODULE,
        loadChildren: () =>
          import('./pages/accounts/accounts.module').then(
            (m) => m.AccountsModule
          ),
      },
      {
        path: AppRoutes.PROJECTMODULE,
        loadChildren: () =>
          import('./pages/project/project.module').then((m) => m.ProjectModule),
      },
      {
        path: AppRoutes.UNIVERSITYMODULE,
        loadChildren: () =>
          import('./pages/university/university.module').then(
            (m) => m.UniversityModule
          ),
      },
      {
        path: AppRoutes.CONTACTMODULE,
        loadChildren: () =>
          import('./pages/contact/contact.module').then((m) => m.ContactModule),
      },
      {
        path: AppRoutes.RESOURCESMODULE,
        loadChildren: () =>
          import('./pages/resource/resource.module').then(
            (m) => m.ResourceModule
          ),
      },
      {
        path: AppRoutes.DYNAMICMODULE,
        loadChildren: () =>
          import('./pages/dynamic/dynamic.module').then((m) => m.DynamicModule),
      },
      {
        path: AppRoutes.ICMDETAILS,
        loadChildren: () =>
          import('./pages/icm-details/icm-details.module').then(
            (m) => m.IcmDetailsModule
          ),
      },
      {
        path: AppRoutes.CONCERNEDPERSONSDETAILS,
        loadChildren: () =>
          import(
            './pages/concerned-person-details/concerned-person-details.module'
          ).then((m) => m.ConcernedPersonDetailsModule),
      },
      {
        path: AppRoutes.IMPORT,
        component: ImportComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
