export class Helper {
  /**
   * fetch data from json file
   * @param cb
   * @param path
   */
  static fetchDataJson(cb: any, path: string): any {
    const req = new XMLHttpRequest();
    req.open('GET', path);
    req.onload = () => {
      cb(JSON.parse(req.response));
    };
    req.send();
  }

  /**
   * remove element from array
   * @param array
   * @param element
   * @param value
   */
  static removeElement(array: any[], element: string, value: string): void {
    const index = array.findIndex((x) => x[element].toString() === value);
    if (index >= 0) {
      array.splice(index, 1);
    }
  }

  /**
   * get object
   * @param object
   * @param properties
   */
  static getObjectValues(
    obj: any | object,
    properties: string[]
  ): any | object {
    const val = {};
    properties.forEach((item) => {
      if (obj[item]) {
        val[item] = obj[item];
      }
    });
    return val;
  }
}
