import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationComponent } from './layouts/authentication/authentication.component';
import { PrivateComponent } from './layouts/private/private.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { Router } from '@angular/router';
import { Redirection } from './core/utils/redirection';
import { AuthorizedGuard, LoggedInGuard } from './core/services/guards';
import { HttpTokenInterceptor } from './core/services/interceptors';
import { AppService } from './core/services/global/app.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlugifyPipe } from './core/helpers/slugify.pipe';

export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

export const app_init = (appService: AppService) => {
  return () => appService.initializeApp();
};
export let appInjector: Injector;

@NgModule({
  declarations: [AppComponent, AuthenticationComponent, PrivateComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'fr',
    }),
    MatSidenavModule,
    MatIconModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatButtonModule,
    MatListModule,
    MatMenuModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [
    AuthorizedGuard,
    LoggedInGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: app_init,
      deps: [AppService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    SlugifyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injectorService: Injector, private router: Router) {
    appInjector = this.injectorService;
    Redirection.route = router;
  }
}
