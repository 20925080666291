import { appInjector } from 'src/app/app.module';
import { CrudService } from '../services/global/crud.service';
import { FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { CofirmationDialogService } from '../services/global/cofirmation-dialog.service';
import { debounceTime, delay, switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';

export class Listing {
  model;
  crudService;
  search;
  searchTerme = new Subject<string>();
  searchForm: FormGroup;
  dialogService;

  /* pagination  vars */
  // MatPaginator Inputs
  pageSize = 20;

  // MatPaginator Output
  pageEvent: PageEvent;
  /* end of  pagination  vars */

  constructor(model) {
    this.model = model;
    this.crudService = appInjector.get(CrudService) as CrudService;
    this.dialogService = appInjector.get(
      CofirmationDialogService
    ) as CofirmationDialogService;
    this.realSerarch();
  }

  /**
   * READ DATA
   */
  read(params?: HttpParams): void {
    this.crudService.read(this.model, params).subscribe();
  }

  /**
   * DELETE DATA BY ID
   * @param id
   */
  delete(id: string): void {
    console.log('clicked');

    this.dialogService.openDialog().subscribe((data) => {
      if (data) {
        this.crudService.delete(this.model, id).subscribe((res) => {
          this.afterDelete(res);
        });
      } else {
        return;
      }
    });
  }

  /**
   * search data
   */
  onSearch(): void {
    this.searchTerme.next(this.search);
  }
  realSerarch() {
    let httpParams = new HttpParams();
    let SearchObservable = this.searchTerme.pipe(
      debounceTime(600),
      switchMap((term) => {
        httpParams = httpParams.append('search', term);
        return this.crudService.read(this.model, httpParams);
      })
    );
    SearchObservable.subscribe();
  }
  /**
   * on filter
   */
  onFilter(): void {
    const value = this.searchForm.value;
    let httpParams = new HttpParams();
    Object.keys(value).forEach((key) => {
      if (value[key]) {
        httpParams = httpParams.append(key, value[key]);
      }
    });
    this.crudService.read(this.model, httpParams).subscribe();
  }

  /**
   * after delete
   * @param value
   */
  afterDelete(value) {}

  /* pagination */

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      // this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }
  hanlePagination(ev: PageEvent) {
    let skip = ev.pageIndex * ev.pageSize;
    let limit = ev.pageSize;
    let params = {
      skip,
      limit,
    };
    this.setHttpParams(params);
  }

  setHttpParams(params?) {
    const httpParams = new HttpParams()
      .set('skip', params.skip)
      .set('limit', params.limit);
    this.read(httpParams);
  }
}
