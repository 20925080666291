import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-file-image',
  templateUrl: './file-image.component.html',
  styleUrls: ['./file-image.component.scss']
})
export class FileImageComponent {

  @Input('image') image = { file: null, type: 'local' };
  @Output('changes') changes = new EventEmitter<any>();
  constructor() { }

    /**
   * console.log('aaa);
   * @param files
   */

  onFileChange(event) {
    const reader = new FileReader();
    const files = event.target.files;
    if (files && files.length) {
      const [file] = files;
      reader.readAsDataURL(file);
      reader.onload = async () => {
        this.image = { file: file, type: 'local' };
        this.changes.emit(this.image);
      };
    }
  }

}
