export enum AppRoutes {
  AUTHMODULE = 'auth',
  CONTENTMODULE = 'content',
  ANALYTICSMODULE = 'analytics',
  ACCOUNTMODULE = 'accounts',
  PROJECTMODULE = 'projects',
  UNIVERSITYMODULE = 'universities',
  CONTACTMODULE = 'contacts',
  RESOURCESMODULE = 'resources',
  CBHEMODULE = 'cbhe',
  EMJMDMODULE = 'emjmd',
  ICMMODULE = 'icm',
  CBHECONTACTMODULE = 'cbhe-contacts',
  EMJMDCONTACTMODULE = 'emjmd-contacts',
  JEANMONNETCONTACTMODULE = 'jean-monnet-contacts',
  ICMCONTACTMODULE = 'icm-contacts',
  ESTABLISHMENTMODULE = 'establishments',
  INTERNATIONALRELATIONMODULE = 'international-relations',
  LESSONMODULE = 'lessons',
  TRAININGOFFERMODULE = 'training-offers',
  JEANMONNETMODULE = 'jean-monnet',
  ACTUALITYCOMPONENT = 'actualities',
  ICMAPPROVEDMODULE = 'icm-approved',
  PROFILECOMPONENT = 'profile-contents',
  USERCOMPONENT = 'users',
  ROLECOMPONENT = 'roles',
  BANNERCOMPONENT = 'banners',
  SIGNINCOMPONENT = 'sign-in',
  RESOURCES = '/resources',
  RESOURCESFORM = '/resources/form',
  RESOURCESFORMID = '/resources/form/:id',
  IMPORT = 'import/:type',
  IMPORTICMTOOLS = '/import/icm_tools',
  IMPORTICMAPPROVED = '/import/icm_approved',
  IMPORTCBHE = '/import/cbhe',
  IMPORTJEANMONNET = '/import/jean_monnet',
  IMPORTEMJMD = '/import/emjmd',

  ICMPROJECTLISTINGCOMPONNETS = 'projects',
  ICMPROJECTFORMCOMPONNETS = 'projects/form',
  ICMPROJECTFORMIDCOMPONNETS = 'projects/form/:id',
  ICMMOBILITYLISTING = 'mobility',
  ICMMOBILITYFORM = 'mobility/form',
  ICMMOBILITYFORMID = 'mobility/form/:id',

  ICMTOOLSLISTINGCOMPONENT = 'tools',
  ICMTOOLSFORMCOMPONENT = 'tools/form',
  ICMTOOLSFORMIDCOMPONENT = 'tools/form/:id',
  BANNERLISTING = '/content/banners',
  BANNERFORM = '/content/banners/form',
  ROLELISTING = '/accounts/roles',
  ROLEFORM = '/accounts/roles/form',
  USERLISTING = '/accounts/users',
  USERFORM = '/accounts/users/form',
  PROFILECONTENTLISTING = '/content/profile-contents',
  PROFILECONTENTFORM = '/content/profile-contents/form',
  ACTUALITYLISTING = '/content/actualities',
  ACTUALITYFORM = '/content/actualities/form',
  CBHELISTING = '/projects/cbhe',
  CBHEFORM = '/projects/cbhe/form',
  UNIVERSITYLISTING = '/universities',
  UNIVERSITYFORM = '/universities/form',
  UNIVERSITYCBHECONTACTS = '/universities/cbhe-contacts',
  CBHECONTACTSFORM = '/universities/cbhe-contacts/form',
  UNIVERSITYICMCONTACTS = '/universities/icm-contacts',
  ICMCONTACTSFORM = '/universities/icm-contacts/form',
  UNIVERSITYEMJMDCONTACTS = '/universities/emjmd-contacts',
  UNIVERSITYJEANMONNETCONTACTS = '/universities/jean-monnet-contacts',
  JEANMONNETCONTACTSFORM = '/universities/jean-monnet-contacts/form',
  UNIVERSITYESTABLISHMENTSCONTACTS = '/universities/establishments',
  ESTABLISHMENTSCONTACTSFORM = '/universities/establishments/form',
  UNIVERSITYTRAININGOFFERSCONTACTS = '/universities/training-offers',
  TRAININGOFFERSCONTACTSFORM = '/universities/training-offers/form',
  UNIVERSITYLESSONSCONTACTS = '/universities/lessons',
  LESSONSCONTACTSFORM = '/universities/lessons/form',
  UNIVERSITYINTERNATIONALRELATIONSCONTACTS = '/universities/international-relations',
  INTERNATIONALRELATIONSCONTACTSFORM = '/universities/international-relations/form',
  EMJMDLISTING = '/projects/emjmd',
  EMJMDFORM = '/projects/emjmd/form',
  JEANMONNETLISTING = '/projects/jean-monnet',
  JEANMONNETFORM = '/projects/jean-monnet/form',
  ICMLISTING = '/projects/icm',
  ERASMUSICMDETAILS = '/icm-details/',
  ICMDETAILS = 'icm-details/:id',
  CONCERNEDPERSONSDETAILS = 'concerned-person-details/:id',
  CONCERNEDPERSONS = '/concerned-person-details/',
  ICMFORM = '/projects/icm/form',
  ICMFORMID = '/projects/icm/form/:id',
  ICMAPPROUVEDLISTING = '/projects/icm-approved',
  ICMAPPROUVEDFORM = '/projects/icm-approved/form',
  ICMAPPROUVEDFORMID = '/projects/icm-approved/form/:id',
  CONTACTFORM = '/contacts/form',
  CONTACTLISTING = '/contacts',
  SIGNIN = '/auth/sign-in',
  FORGOTPASSWORDCOMPONENT = 'forgot-password',
  FORGOTPASSWORD = '/auth/forgot-password',
  RESETPASSWORDCOMPONENT = 'change-password',
  FORM = 'form',
  FORMID = 'form/:id',
  ICMTOOLSFORM = '/projects/icm/tools/form',
  ICMTOOLSFORMID = '/projects/icm/tools/form/:id',
  ICMTOOLSLISTING = '/projects/icm/tools',
  PROJECTSLISTING = '/projects/icm-approved/projects',
  PROJECTSFORM = '/projects/icm-approved/projects/form',
  PROJECTSFORMID = '/projects/icm-approved/projects/form/:id',
  MOBILITYSLISTING = '/projects/icm-approved/mobility',
  MOBILITYSFORM = '/projects/icm-approved/mobility/form',
  MOBILITYSFORMID = '/projects/icm-approved/mobility/form/:id',

  // dynamic part
  DYNAMICMODULE = 'dynamic',
  MENUMODULE = 'menu',
  PAGESMODULE = 'pages',
  EXTERNALPAGESMODULE = 'external-pages',
  PAGESFORM = '/dynamic/pages/form',
  EXTERNALPAGESFORM = '/dynamic/external-pages/form',
  PAGESLISTING = '/dynamic/pages',
  EXTERNALPAGESLISTING = '/dynamic/external-pages',

  PUBLICATIONSMODULE = 'publications',
  PUBLICATIONSLISTING = '/content/publications',
  PUBLICATIONSFORM = '/content/publications/form',
  PUBLICATIONSFILESLISTING = '/content/publications/files',
  PUBLICATIONSFILESFORM = '/content/publications/files/form',

  REOURCESLISTING = '/resources/resources',
  REOURCESFORM = '/resources/resources/form',
  REOURCESFILESLISTING = '/resources/resources-files',
  REOURCESFILESFORM = '/resources/resources-files/form',
  ACTIVITIES = 'activities',
  STANDARDACTIVITIES = 'standard',
  STANDARDACTIVITIESLISTING = '/content/activities/standard',
  STANDARDACTIVITIESFORM = '/content/activities/standard/form',
  REFORMPROJECTSACTIVITIES = 'reform',
  REFORMPROJECTSACTIVITIESLISTING = '/content/activities/reform',
  REFORMPROJECTSACTIVITIESFORM = '/content/activities/reform/form',
  PROJECTSACTIVITIES = 'projects',
  PROJECTSACTIVITIESLISTING = '/content/activities/projects',
  PROJECTSACTIVITIESFORM = '/content/activities/projects/form',

  REFERENCES = 'references',
  REFERENCESLISTING = '/content/references',
  REFERENCESFORM = '/content/references/form',

  DOCUMENTS = 'documents',
  DOCUMENTSLISTING = '/content/documents',
  DOCUMENTSFORM = '/content/documents/form',
}
