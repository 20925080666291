<ng-template #dialog>
  <mat-horizontal-stepper linear #stepper>
    <mat-step>
      <ng-template matStepLabel>choisir le fichier</ng-template>
      <div>
        <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="3px">
          <h3 class="py-2">
            importer un fichier Excel pour un <strong> {{ title }} </strong>
          </h3>
          <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()">
            <input
              type="file"
              name="file"
              (change)="onFileChange($event)"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              #file
            />
          </form>
          <div class="py-2">
            <p>
              fichier sélectionné: <strong>{{ fileName }}</strong>
            </p>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="3px">
        <button mat-button matStepperNext (click)="cancel()">Annuler</button>
        <button
          mat-button
          matStepperNext
          (click)="onSubmit()"
          [disabled]="!uploadForm.valid"
        >
          importer
        </button>
      </div>
    </mat-step>
    <!-- <mat-step>
      <ng-template matStepLabel>mapper le fichier</ng-template>
      <div>
        <p>step two</p>
      </div>
      <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="gappx">
        <button mat-button matStepperPrevious>retour</button>
        <button mat-button matStepperNext>importer</button>
      </div>
    </mat-step> -->
    <mat-step>
      <ng-template matStepLabel>Téléchargement</ng-template>
      <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px">
        <div>
          <p>{{ statusMessage }}</p>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
        <button mat-button matStepperPrevious (click)="close()">finish</button>
        <button mat-button (click)="stepper.reset()">
          importer un autre fichier
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</ng-template>
