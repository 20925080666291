import { Injectable } from '@angular/core';
import { Credentials, Response } from '../../models/interfaces';
import { Observable, throwError } from 'rxjs';
import { ApiService } from '../global/api.service';
import { map, tap, catchError } from 'rxjs/operators';
import { Auth } from '../../models/interfaces/auth';
import { UserService } from '../global/user.service';
import { JsonWebToken } from '../../utils';
import { Redirection } from '../../utils/redirection';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private apiService: ApiService,
    private userService: UserService
  ) {}

  /**
   * login
   * @param credentials
   */
  signIn(credentials: Credentials): Observable<Auth> {
    return this.apiService.post('auth/sign-in', credentials).pipe(
      map((auth: Auth) => {
        this.userService.isLoged.next(true);
        this.userService.setUser(auth.user);
        JsonWebToken.setCredentials(auth.token, auth.refreshToken);
        return auth;
      })
    );
  }

  /**
   * forgot password
   * @param value
   */
  forgotPassword(email: string): Observable<Response> {
    return this.apiService.post('auth/forgot-password', { email });
  }

  /**
   * forgot password
   * @param password
   * @param token
   */
  resetPassword(password: string, token: string): Observable<Response> {
    return this.apiService.post('auth/reset-password', { password, token });
  }

  /**
   * check if authorized and get user info
   */
  isLoggedIn(): any {
    return this.apiService.post('auth/is-authorized').pipe(
      tap((user: any) => {
        this.userService.isLoged.next(true);
        this.userService.setUser(user);
      }),
      catchError((err) => {
        this.userService.isLoged.next(false);
        return throwError(false);
      })
    );
  }

  /**
   * logout
   */
  logout(): any {
    this.userService.isLoged.next(false);
    JsonWebToken.destroyToken();
    Redirection.signIn();
  }

  //  /**
  //  * refresh token
  //  */
  //   refreshToken(): Observable<{ token: string; refreshToken: string }> {
  //     const refreshToken = this..get('refreshToken');
  //     return this.apiService.post('/auth/refresh', { refreshToken }).pipe(
  //       tap((response) => {
  //         this.localStorageService.set('token', response.accessToken);
  //         this.localStorageService.set('refreshToken', response.refreshToken);
  //       })
  //     );
  //   }
}
