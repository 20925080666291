import { FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { appInjector } from 'src/app/app.module';
import { CrudService } from '../services/global/crud.service';
import * as ClassicEditor from 'src/app/ckeditorCustomBuild/build/ckeditor';

export abstract class Form {
  form: FormGroup;
  model;
  crudService;

  editorConfig: AngularEditorConfig = {
    minHeight: '600',
    editable: true,
    toolbarPosition: 'bottom',
    height: '700',
    toolbarHiddenButtons: [[], []],
    customClasses: [
      {
        name: 'full-width-image',
        class: 'w-100',
      },
      {
        name: 'half-width-image',
        class: 'w-50 d-inline',
      },
      {
        name: 'display-inline',
        class: 'd-inline',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  constructor(model) {
    this.model = model;
    this.crudService = appInjector.get(CrudService) as CrudService;
  }

  /**
   * CREATE OR UPDATE
   */
  onSave(): void {
    let value = this.form.value;
    this.beforeSave(value);
    const tag = new this.model(value);

    const observable = tag.id
      ? this.crudService.update(this.model, tag)
      : this.crudService.create(this.model, tag.values);
    observable.subscribe((res) => {
      this.form.reset();
      this.afterSave(res);
    });
  }
  /**
   * on save for form data where put is not supported
   */
  onSaveFix(): void {
    let value = this.form.value;
    this.beforeSave(value);
    const tag = new this.model(value);
    let newModal = {
      tableName: this.model.tableName + '/' + tag.id,
    };
    const observable = tag.id
      ? this.crudService.create(newModal, tag.values)
      : this.crudService.create(this.model, tag.values);
    observable.subscribe((res) => {
      this.form.reset();
      this.afterSave(res);
    });
  }

  /**
   * read one by id
   * @param id
   */
  readOne(id: string): void {
    this.crudService.readOne(this.model, id).subscribe((res) => {
      this.form.patchValue(res);
      this.afterReadOne(res);
    });
  }

  /**
   * AFTER SAVE
   */
  afterSave(res) {}

  /**
   * BEFORE SAVE
   * @param value
   */
  beforeSave(value: any) {}

  /**
   * after read one
   * @param value
   */
  afterReadOne(value: any) {}

  //ckeditor settings
  public Editor = ClassicEditor;
  public config = {
    allowedContent: 'iframe[*]',
    toolbar: {
      // 'heading',
      // '|',
      // 'bold',
      // 'italic',
      // 'ImageUpload',
      // 'Alignment',
      // 'insertTable',
      // 'TableToolbar',
      // // 'bulletedList',
      // 'numberedList',
      // 'todoList',
      // 'link',
      // 'code',
      // 'Essentials',
      items: [
        'heading',
        '|',
        'fontfamily',
        'fontsize',
        '|',
        'alignment',
        '|',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'bold',
        'italic',
        'strikethrough',
        'underline',
        'subscript',
        'superscript',
        '|',
        'link',
        '|',
        'outdent',
        'indent',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'code',
        'codeBlock',
        '|',
        'insertTable',
        '|',
        'uploadImage',
        'blockQuote',
        '|',
        'HtmlEmbed',
        'MediaEmbed',
        '|',
        'undo',
        'redo',
      ],
      shouldNotGroupWhenFull: true,
    },
    blockToolbar: [
      'paragraph',
      'heading1',
      'heading2',
      'heading3',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'blockQuote',
      'uploadImage',
    ],

    // This value must be kept in sync with the language defined in webpack.config.js.
    language: 'en',
    image: {
      // Configure the available styles.
      styles: ['alignLeft', 'alignCenter', 'alignRight'],

      // Configure the available image resize options.
      resizeOptions: [
        {
          name: 'resizeImage:original',
          label: 'Original',
          value: null,
        },
        {
          name: 'resizeImage:50',
          label: '50%',
          value: '50',
        },
        {
          name: 'resizeImage:75',
          label: '75%',
          value: '75',
        },
      ],

      // You need to configure the image toolbar, too, so it shows the new style
      // buttons as well as the resize buttons.
      toolbar: [
        'imageStyle:alignLeft',
        'imageStyle:alignCenter',
        'imageStyle:alignRight',
        '|',
        'resizeImage',
        '|',
        'imageTextAlternative',
      ],
    },
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
    },
  };
}
