import { Project } from './project';

export class Emjmd extends Project {

    static tableName = 'emjmd';

    constructor(emjmd?: any) {
        super(emjmd);
    }

}