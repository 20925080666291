export class Import {
  details = {
    icm_tools: {
      endPoint: 'import_icm_tools_projet',
      title: 'ICM outils',
      listingUrl: 'ICMLISTING',
    },
    icm_approved: {
      endPoint: 'import_icm_approved',
      title: 'ICM approuvé',
      listingUrl: 'ICMAPPROUVEDLISTING',
    },
    emjmd: {
      endPoint: 'import_emjmd',
      title: 'EMJMD',
      listingUrl: 'EMJMDLISTING',
    },
    cbhe: {
      endPoint: 'import_cbhe',
      title: 'CBHE',
      listingUrl: 'CBHELISTING',
    },
    jean_monnet: {
      endPoint: 'import_jean_monnet',
      title: 'Jean monnet',
      listingUrl: 'JEANMONNETLISTING',
    },
  };
}
