import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Import } from 'src/app/core/models/classes/import';
import { AppRoutes } from 'src/app/core/models/enums';
import { ApiService } from 'src/app/core/services/global/api.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
})
export class ImportComponent extends Import implements OnInit, AfterViewInit {
  isSelected: boolean = false;
  fileName: string;
  excelData: any;
  maxNumber = 5;
  dialogRef;
  type: string;
  title: string;
  statusMessage: string;
  @ViewChild('dialog') dialogContent;
  uploadForm: FormGroup;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    public route: Router
  ) {
    super();
  }

  ngOnInit() {
    const { type } = this.activatedRoute.snapshot.params;
    if (type) {
      this.type = type;
      console.log(type);
      this.title = this.details[type].title;
    }
    this.initForm();
  }

  initForm(): void {
    this.uploadForm = this.fb.group({
      file: ['', Validators.required],
    });
  }
  ngAfterViewInit(): void {
    this.dialogRef = this.dialog.open(this.dialogContent, {
      width: '80%',
      data: {},
      disableClose: true,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
    });
  }

  /**
   * console.log('aaa);
   * @param files
   */

  onFileChange(ev) {
    // console.log('file change start');
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    this.fileName = file.name;
    this.uploadForm.get('file').setValue(file);
    this.isSelected = true;

    // const target = ev.target.result;
    // const wb = XLSX.read(target, {
    //   type: 'binary',
    //   cellText: false,
    //   cellDates: true,
    // });
    // const wsname = wb.SheetNames[0];
    // const ws = wb.Sheets[wsname];
    // const data = XLSX.utils.sheet_to_json(ws, {
    //   header: 1,
    //   raw: false,
    //   CC
    // });
    // console.log(data);

    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, {
        type: 'binary',
        cellDates: true,
        dateNF: 'mm/dd/yyyy',
      });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet, {
          defval: '',
          raw: false,
        });
        return initial;
      }, {});
      console.log('json data', jsonData.Sheet1);
      this.excelData = jsonData.Sheet1;
      // this.factorDataToSend(jsonData.Sheet1);
      // this.excelData = jsonData.Worksheet.slice(0, this.maxNumber);
      jsonData = null;
      // console.log(this.excelData);
    };
    reader.readAsBinaryString(file);
  }

  onSubmit() {
    this.apiService.post('excel-file', this.excelData).subscribe(
      (res) => {
        console.log(res);
        this.statusMessage = res.message;
      },
      (err) => {
        console.log(err);
        this.statusMessage = err.error.message;
      }
    );
  }
  // onSubmit() {
  //   this.statusMessage = 'loading';
  //   const formData = new FormData();
  //   formData.append('file', this.uploadForm.get('file').value);
  //   this.apiService.post(this.details[this.type].endPoint, formData).subscribe(
  //     (res) => {
  //       console.log(res);
  //       this.statusMessage = res.message;
  //     },
  //     (err) => {
  //       console.log(err);
  //       this.statusMessage = err.error.message;
  //     }
  //   );
  // }

  close() {
    this.goBack();
  }
  cancel() {
    this.goBack();
  }
  goBack() {
    this.dialogRef.close(true);
    this.route.navigateByUrl(AppRoutes[this.details[this.type].listingUrl]);
  }

  factorDataToSend(array: []) {
    let data = [];
    let universitiesIds = [];
    let currentUniveristyId: number;
    let projectsCodes = [];
    let currentProjectCode: any;
    let currentProject: any;
    let currentConcerendPerson: any;

    const add = () => {
      console.log('add called', universitiesIds);
    };
    add();

    let finalData = [
      {
        university_id: 1,
        projects: [
          {
            code: 'project-code',
            date: 'lksdjf',
            concernedPeople: [
              {
                type: 'student',
                incomimng_mb: 'lksflj',
              },
            ],
          },
        ],
      },
    ];

    array.forEach((row: any) => {
      let universityId = row.code_university;
      let projectCode = row.code;

      currentUniveristyId = universityId;
      currentProjectCode = projectCode;
      currentProject = {};
      currentConcerendPerson = {};

      // check if university id is in universitiesIds;
      //if yes
      //check if prjectcode is in the projectscodes
      //if yes
      //add new person
      //if no
      //add new project (push it's code to the projects codes)
      // add new person
      //if no
      //add new row to the final data
      //add new project (push it's code to the projects codes)
      //add new person
      //add new university id (push it's id to the universities ids)
    });

    array.forEach((item: any) => {
      data.push({
        university_id: item.code_university,
        icm: {
          partner_country: item.partner_country,
        },
      });
    });
    this.excelData = data;
    console.log('final data', this.excelData);
  }
}
