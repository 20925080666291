import { Injectable } from '@angular/core';
import { User } from '../../models/classes';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user: User;
  isLoged = new BehaviorSubject(false);

  /**
   * change current user value
   * @param values
   */
  setUser(values): void {
    this.user = new User(values);
  }

  //todo edit profile
  editProfile() { }

  //todo change connected user password
  changePassword() { }

}
