import { Helper } from '../../utils';

export class Content {
  private _id: number;
  private _title: string;
  private _content: string;
  private _image: string;
  private _publish: boolean;
  private _slug: boolean;
  private _description: boolean;

  constructor(content?: any) {
    this.id = content.id;
    this.title = content.title;
    this.content = content.content;
    this.image = content.image;
    this.publish = content.publish;
    this.slug = content.slug;
    this.description = content.description;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get title(): string {
    return this._title;
  }

  public set title(title: string) {
    this._title = title;
  }

  public get content(): string {
    return this._content;
  }

  public set content(content: string) {
    this._content = content;
  }

  public get image(): string {
    return this._image;
  }

  public set image(image: string) {
    this._image = image;
  }

  public get publish(): boolean {
    return this._publish;
  }

  public set publish(publish: boolean) {
    this._publish = publish;
  }
  public get slug(): boolean {
    return this._slug;
  }

  public set slug(slug: boolean) {
    this._slug = slug;
  }

  public get description(): boolean {
    return this._description;
  }

  public set description(description: boolean) {
    this._description = description;
  }

  /**
   * get value
   */
  public get values(): object {
    let formData = new FormData();
    const properties = [
      'id',
      'title',
      'image',
      'content',
      'publish',
      'slug',
      'description',
    ];
    properties.forEach((item) => {
      if (this[item]) {
        formData.append(item, this[item]);
      }
    });
    return formData;
  }
}
