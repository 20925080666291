import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'erasmus-admin';

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.initCustomIcons();
  }

  /**
 * init custom icons
 */
  private initCustomIcons() {
    this.matIconRegistry.addSvgIcon('app_campaign', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/campaign.svg'));
    this.matIconRegistry.addSvgIcon('app_dashboard', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard.svg'));
    this.matIconRegistry.addSvgIcon('app_network', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/network.svg'));
    this.matIconRegistry.addSvgIcon('app_right_arrow', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/right-arrow.svg'));
    this.matIconRegistry.addSvgIcon('app_setting', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/settings.svg'));
    this.matIconRegistry.addSvgIcon('app_account', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/user.svg'));
    this.matIconRegistry.addSvgIcon('app_notification', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/notification.svg'));
  }

}
