import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(public injector: Injector) { }

  initializeApp(): Promise<any> {
    return new Promise(((resolve, reject) => {
      const authService = this.injector.get(AuthService);
      authService.isLoggedIn()
        .toPromise()
        .then(res => {
          resolve();
        })
        .catch(err => {
          resolve();
        })
    }))
  }

}
